.datePickerWrapper {
  width: 421px;
  border-radius: 500px;
  border: 1px solid #3c403e;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;

  font-family: Moderat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #b5b5b5;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 301px;
  }

  .picker {
    width: 85%;
    cursor: pointer;
    z-index: 99;

    .rdt {
      width: 100%;
      input {
        width: 100%;
      }
    }

    .rdtPicker {
      background-color: black;
      border: none;

      td:hover,
      th:hover,
      .rdtBtn:hover {
        background-color: #63cda01f;
      }

      td:hover {
        border-radius: 22px;
      }

      .rdtTime td:hover {
        background-color: transparent;
      }

      td.rdtActive,
      td.rdtActive:hover {
        color: #63cda0;
        background: #63cda01f;
        border-radius: 22px;
      }
    }
  }
}
