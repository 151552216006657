button {
  background-color: #63cda0;
  color: #151619;
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 471px;
  height: 60px;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    width: 351px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.disabled {
  background: #55555580;
  color: #999999;
  pointer-events: none;
}
