.footer-container {
  min-height: 180px;
  overflow-x: hidden;

  overflow: hidden;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #151619;

  background: radial-gradient(
    50% 90% at 50% 100%,
    #63cda0 0%,
    rgba(99, 205, 160, 0.465) 0.01%,
    rgba(99, 205, 160, 0.186948) 13.5%,
    rgba(99, 205, 160, 0) 78.5%,
    rgba(26, 26, 26, 0.9)
  );
}

.footer-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.footer-header {
  font-family: Moderat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #999999;
}

.footer-title {
  font-family: Moderat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #dddddd;
  margin-bottom: 8px;
  margin-right: 64px;

  a {
    text-decoration: none;
    color: #dddddd;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
.footer-title-container {
  flex: 0.8;
  padding: 36px 90px 0;

  @media screen and (max-width: 960px) {
    flex: 1;
    padding: 36px 0 0 36px;
  }
}
.footer-img-container {
  flex: 0.2;
  padding: 48px 100px 0;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 960px) {
    flex: 1;
    justify-content: flex-start;
    padding: 36px 0 0 36px;
  }
}

.footer-header-row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.footer-header-row-mob {
  display: none;
  @media screen and (max-width: 960px) {
    display: flex;
  }
}

.footer-copyright-container {
  display: flex;
  flex-direction: row;
  padding: 12px 90px;
  p {
    font-family: Moderat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
    margin-right: 40px;
  }

  @media screen and (max-width: 960px) {
    padding: 12px 36px;
  }
}
