.cardContainer {
  padding: 24px;
  border-radius: 18px;
  background: #222222;

  .heading {
    font-family: Moderat;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #63cda0;
  }

  .subtitle {
    font-family: Moderat;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    white-space: pre-line;
    word-break: break-all;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    img {
      margin-left: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.popup {
  position: relative;
  display: flex;

  p {
    font-family: Moderat;
    background-color: white;
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    margin-left: -10px;
    opacity: 1;
    transition: opacity 0.3s;
    margin-bottom: 10%;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}
