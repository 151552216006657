.md5Cta {
  margin: 24px 0;
}

.md5-info-para {
  width: 90%;
  padding: 24px;
  /* border-radius: 24px; */

  border-top: 1px solid #3c403e;
}

.md5-info-header {
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #63cda0;
}

.md5-info-text {
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
