.info-wrapper {
  font-family: Moderat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #dddddd;
  margin: 16px 0;
}

.info-container {
  margin: 24px 0;
}

.info-title-container {
  margin-top: 24px;
}
