.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #1a1a1ae5;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.error-nav-container {
  background: #151619;
}

.nav-title {
  font-family: Moderat;
  font-size: 24px;
  font-weight: 502;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 40px;
  border-bottom: 4px solid transparent;
  width: fit-content;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.selected-nav {
  border-bottom: 4px solid #63cda0;
}

.nav-logo {
  height: 256px;
  margin-bottom: 156px;
  margin-right: 64px;

  @media screen and (max-width: 960px) {
    margin-right: 0px;
    height: 106px;
    margin-bottom: 0px;
    padding-bottom: 28px;
  }
}

.navlogo-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media screen and (max-width: 960px) {
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 24px;
    margin-top: -24px;
  }
}

.navtitle-wrapper {
  flex: 1;
  padding: 64px 100px;

  @media screen and (max-width: 960px) {
    padding: 24px;
  }
}

.nav-close {
  font-family: Moderat;
  font-size: 18px;
  font-weight: 502;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  &:hover {
    opacity: 0.8;
  }
}

.close-line {
  width: 44px;
  background-color: #999999;
  height: 2px;
  margin-right: 12px;
}

.navtitle-container {
  margin-top: 64px;

  @media screen and (max-width: 960px) {
    margin-top: 36px;
  }
}

@keyframes slideInDownAnim {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

.slideInDown {
  animation-name: slideInDownAnim;
  -webkit-animation-name: slideInDownAnim;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  z-index: 1;
}

@keyframes slideInUpAnim {
  0% {
    height: 100%;
  }

  100% {
    height: 0;
  }
}

.slideInUp {
  animation-name: slideInUpAnim;
  -webkit-animation-name: slideInUpAnim;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0px, 30px);
  }
  40% {
    opacity: 0;
    transform: translate(0px, 10px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 30px);
  }
  40% {
    opacity: 0;
    -webkit-transform: translate(0px, 10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 1.25s;
  -webkit-animation-duration: 1.25s;
}

.delayed {
  animation-duration: 1.75s;
  -webkit-animation-duration: 1.75s;
}

.hide-container {
  -webkit-animation: hideAnim 0.2s forwards;
  animation: hideAnim 0.2s forwards;
}

@keyframes hideAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hideAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
