.dateToTsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 507px;
  padding: 24px 16px;

  & > * {
    margin-bottom: 24px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
