.dropdownContainer {
  width: 471px;
  border-radius: 18px;
  background-color: #212222;
  border: 1px solid #3c403e;
  box-shadow: 0px 4px 4px 0px #00000040;
  overflow: hidden;

  @media screen and (max-width: 960px) {
    width: 351px;
  }
}

.closedContainer {
  border-radius: 500px;
  background-color: transparent;
  box-shadow: none;

  .selectedItem {
    background-color: transparent;
    border: none;
  }
}

.selectedItem {
  background-color: #313835;
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #dddddd;
  padding: 16px 24px;
  border-bottom: 2px solid #63cda0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 960px) {
    font-size: 18px;
    line-height: 21.6px;
  }

  .selected {
    width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.menuItem {
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #dddddd;
  padding: 14px 23px 20px;
  border-bottom: 1px solid #3c403e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    font-size: 18px;
    line-height: 21.6px;
  }

  &:last-child {
    border-bottom: none;
  }

  .title {
    width: 85%;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
