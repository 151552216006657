@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("assets/fonts/Moderat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Advent Pro";
  src: url("assets/fonts/AdventPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}
