.lpPageContainer {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #151619;

  background-image: url("assets/images/container-circle.png");
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
}

.lp-header-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 120px);
  justify-content: space-between;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
    min-height: calc(100% - 60px);
  }
}

.menu-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 52px 90px 0;
  cursor: pointer;
  display: block;

  @media screen and (max-width: 960px) {
    display: none;
  }

  &:hover {
    opacity: 0.8;
  }
}

.menu-icon-mob {
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    padding: 14px 16px 0;
    width: 32px;
    height: 32px;
  }
}

.unixTime {
  position: absolute;
  top: 0;
  right: 0;
  padding: 52px 90px 0;
  display: flex;
  flex-direction: column;
  font-family: Moderat;
  font-size: 24px;
  font-weight: 502;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 19.2px;
    padding: 20px 16px;
  }

  span {
    font-family: Moderat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #999999;

    @media screen and (max-width: 960px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 52px 90px 0;

  @media screen and (max-width: 960px) {
    padding: 0;
    position: absolute;
    left: 40px;
    top: 20px;
    justify-content: flex-start;
  }
}

.pageBody {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;
}

.pageResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;

  @media screen and (max-width: 960px) {
    margin-top: -24px;
  }
}

.tsCta {
  margin: 12px 0;
}

.tsInfo {
  font-family: Moderat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
  margin: 0;
  margin-bottom: 32px;
  max-width: 507px;

  @media screen and (max-width: 960px) {
    max-width: 320px;
    width: 100%;
  }
}

.knowMore {
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #63cda0;
  cursor: pointer;
  margin: 0;
}

.epoc-header {
  background-color: #151619;
  height: 120px;
  position: sticky;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 960px) {
    height: 60px;
  }
}

.nav-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 102;
  top: 0;
}

.pageHeader-title {
  color: #63cda0;
  font-family: Advent Pro;
  font-size: 42px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 16px;

  @media screen and (max-width: 960px) {
    font-size: 20px;
    line-height: 19.14px;
  }
}

.pageHeader-logo {
  @media screen and (max-width: 960px) {
    display: none;
  }
}

.header-popup {
  z-index: 103;
  width: 70px;
  height: 20px;
  position: absolute;
  right: 90px;
  top: 40px;
}
