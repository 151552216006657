.result-container {
  width: 90%;
  margin-bottom: 36px;
}

.header {
  font-family: Moderat;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #63cda0;

  .res-subtitle {
    font-family: Moderat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
  }

  .green-line {
    color: #63cda0;
    font-weight: 300;
  }
}

.divider {
  width: 100%;
  border-bottom: 1px solid #3c403e;
}

.result-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  & > * {
    flex: 1;
    margin: 12px;
  }
}
