.inputContainer {
  width: 421px;
  border-radius: 500px;
  border: 1px solid #3c403e;

  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    width: 301px;
  }
}

input {
  background-color: transparent;
  border: none;
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #dddddd;
  width: 70%;
  outline: none;

  *:focus {
    outline: none;
  }
}

.subtitle {
  font-family: Moderat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
